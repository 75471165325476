import ApplicationController from 'modules/application_controller';
import remove from 'plugins/element/remove';

export default class extends ApplicationController {
  initialize() {
    this._loadCheckInterval = null;
    this._queuedCommands = [];
  }

  connect() {
    if (this.isTurboPreview || document.documentElement.hasAttribute('data-turbo-preview')) return;

    this._load();
  }

  disconnect() {
    if (this._loadCheckInterval) {
      clearInterval(this._loadCheckInterval);
      this._loadCheckInterval = null;
    }
  }

  destroy() {
    if (this.loaded) {
      try {
        this.widget.call('destroy');
      } catch (error) {
        console.warn(error);
      }
    }

    for (const tag of document.querySelectorAll('body script[src^="https://cht-srvc.net/"]')) {
      remove(tag);
    }

    if (this.widgetElement) {
      remove(this.widgetElement);
    }
  }

  hide() {
    this._callOnWidget('hide');
  }

  minimize() {
    this._callOnWidget('minimize');
  }

  maximize() {
    this._callOnWidget('maximize');
  }

  _load() {
    this.destroy();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = 'https://cht-srvc.net/api/lc.js?client=sdlv_slideslive';
    document.body.appendChild(script);

    const loadCallback = () => {
      if (this.loaded) {
        clearInterval(this._loadCheckInterval);
        this._loadCheckInterval = null;

        this._loadCallback();
      }
    };

    this._loadCheckInterval = setInterval(loadCallback, 25);
  }

  _loadCallback() {
    this.widget.on('ready', () => {
      for (const command of this._queuedCommands) {
        this.widget.call(command);
      }

      this._queuedCommands = [];

      if (this.hideOnLoad) {
        this.hide();
      }
    });
  }

  _callOnWidget(command) {
    if (this.widget) {
      this.widget.on('ready', () => {
        this.widget.call(command);
      });
    } else {
      this._queuedCommands.push(command);
    }
  }

  get loaded() {
    return !!window.LiveChatWidget;
  }

  get widget() {
    return window.LiveChatWidget;
  }

  get widgetElement() {
    return document.getElementById('chat-widget-container');
  }

  get hideOnLoad() {
    return this.element.dataset.hideOnLoad === 'true';
  }
}
